
import { initializeApp } from "firebase/app";

import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDU15Krn7-Ra5RLg9d9Rekotl6qPlaRwwE",
  authDomain: "desilexfun.firebaseapp.com",
  projectId: "desilexfun",
  storageBucket: "desilexfun.appspot.com",
  messagingSenderId: "139177927281",
  appId: "1:139177927281:web:0ec3369aaeb0516d4a31d0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


export default db;
import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import Inicio from './Inicio';
import Juego from './Juego';
import Juego1 from './Juego1';
const Stack = createStackNavigator();

export default function App() {
  return (
    <NavigationContainer>
      <Stack.Navigator initialRouteName="Inicio">
        <Stack.Screen name="Inicio" component={Inicio} />
        <Stack.Screen name="Juego" component={Juego} />
        <Stack.Screen name="Juego1" component={Juego1}/>
      </Stack.Navigator>
    </NavigationContainer>
  );
}

import React, { useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Animated, Easing } from 'react-native';
import { useNavigation } from '@react-navigation/native';

export default function Inicio() {
  const navigation = useNavigation();
  const moveAnimation = new Animated.Value(0);

  useEffect(() => {
    // Iniciar la animación en un bucle infinito
    Animated.loop(
      Animated.sequence([
        Animated.timing(moveAnimation, {
          toValue: 1,
          duration: 1000,
          easing: Easing.linear,
          useNativeDriver: true,
        }),
        Animated.timing(moveAnimation, {
          toValue: 0,
          duration: 1000,
          easing: Easing.linear,
          useNativeDriver: true,
        }),
      ]),
      {
        iterations: -1, // Infinitas iteraciones
      }
    ).start();
  }, []);

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Presione Desilex para comenzar la aventura</Text>
      <TouchableOpacity onPress={() => navigation.navigate('Juego')}>
        <Animated.Text
          style={[
            styles.button,
            {
              transform: [
                {
                  translateX: moveAnimation.interpolate({
                    inputRange: [0, 1],
                    outputRange: [-200, 200], // Ajusta estos valores según el rango de movimiento deseado
                  }),
                },
              ],
            },
          ]}
        >
          Desilex
        </Animated.Text>
      </TouchableOpacity>
    </View>
  );
}

 

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 20,
    marginBottom: 20,
  },
  button: {
    fontSize: 24,
    backgroundColor: 'lightblue',
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 10,
  },
});

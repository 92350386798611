import React, { useState, useEffect } from 'react';

import db from './firebaseConfig';
import { collection, addDoc, getDocs, query, orderBy } from 'firebase/firestore';
import { View, Text, TouchableOpacity, TextInput, StyleSheet, Animated, Dimensions} from 'react-native';
const window = Dimensions.get('window');
const GameScreen = () => {
  const wordPairs = [
    { dyslexic: '3scu3la', correct: 'escuela' },
    { dyslexic: 'l1br0', correct: 'libro' },
    { dyslexic: 'p4l4bra', correct: 'palabra' },
    { dyslexic: '4m1g0', correct: 'amigo' },
    { dyslexic: 'c0l0r', correct: 'color' },
    { dyslexic: 'núm3r0', correct: 'número' },
    { dyslexic: '3sp4c1o', correct: 'espacio' },
    { dyslexic: 't13mp0', correct: 'tiempo' },
    { dyslexic: '3scr1b1r', correct: 'escribir' },
    { dyslexic: 'l3er', correct: 'leer' },
    { dyslexic: 'd1bujo', correct: 'dibujo' },
    { dyslexic: 'jugu3t3', correct: 'juguete' },
    { dyslexic: 'mús1ca', correct: 'música' },
    { dyslexic: 'p3l1cula', correct: 'película' },
    { dyslexic: 'canc1ón', correct: 'canción' },
   ];
  const [timer, setTimer] = useState(0);
  const [timerId, setTimerId] = useState(null);
  const [playerName, setPlayerName] = useState('');
  const [ranking, setRanking] = useState([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [targetWord, setTargetWord] = useState(wordPairs[currentWordIndex].dyslexic);

  const [isCorrect, setIsCorrect] = useState(null);
  const [selectedWord, setSelectedWord] = useState(null);
  const randomWords = wordPairs.map(pair => pair.correct); // Usar las palabras correctas como palabras aleatorias
  const [gameFinished, setGameFinished] = useState(false); 
  const [moveAnim] = useState(new Animated.Value(0));
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [shuffledWordPairs, setShuffledWordPairs] = useState([]);
 
  const initialWordAnimations = wordPairs.map(() => new Animated.ValueXY({ x: 0, y: 0 }));
  const [wordAnimations, setWordAnimations] = useState(initialWordAnimations);


  useEffect(() => {
    // Mezclar las palabras al iniciar el componente y establecer la primera palabra
    const shuffledPairs = shuffleArray([...wordPairs]);
    setShuffledWordPairs(shuffledPairs);
    setCurrentWordIndex(0); // Comenzar con el primer índice del arreglo mezclado
    setTargetWord(shuffledPairs[0].dyslexic); // Establece la primera palabra mezclada
  }, []);
 // Función para mezclar las palabras
 const shuffleArray = (array) => {
  let currentIndex = array.length, randomIndex;
   // Mientras queden elementos a mezclar...
   while (currentIndex !== 0) {
    // Seleccionar un elemento restante...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // E intercambiarlo con el elemento actual
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
};
useEffect(() => {
  // Mezclar las palabras al iniciar el componente
  shuffleArray(wordPairs);
}, []);

  useEffect(() => {

    // Iniciar el cronómetro cuando el juego comienza
    const id = setInterval(() => {
      setTimer(prev => prev + 1);
    }, 1000);
    setTimerId(id);

    return () => clearInterval(id); // Limpiar el intervalo
  }, []);
    useEffect(() => {
      // Iniciar animaciones para cada palabra
      wordAnimations.forEach(anim => {
        Animated.loop(
          Animated.sequence([
            Animated.timing(anim, {
              toValue: { x: Math.random() * 300 - 150, y: Math.random() * 300 - 150 }, // Valores aleatorios más grandes
              duration: 10000,
              useNativeDriver: true,
            }),
            Animated.timing(anim, {
              toValue: { x: 0, y: 0 },
              duration: 10000,
              useNativeDriver: true,
            }),
          ]),
        ).start();
      });
    }, []);

  
  
    const checkWord = (word) => {
      setSelectedWord(word);
      const isAnswerCorrect = word === shuffledWordPairs[currentWordIndex].correct;
      setIsCorrect(isAnswerCorrect);
    
      if (isAnswerCorrect) {
        const nextIndex = currentWordIndex + 1;
        if (nextIndex < shuffledWordPairs.length) {
          // Si aún hay palabras en el ciclo, muestra la siguiente
          setTimeout(() => {
            setCurrentWordIndex(nextIndex);
            setTargetWord(shuffledWordPairs[nextIndex].dyslexic);
            setIsCorrect(null);
            setSelectedWord(null);
          }, 100);
        } else {
          // Si se han mostrado todas las palabras, puedes reiniciar o terminar el juego
          setGameFinished(true);
          handleGameFinish();
        }
      }
    };
    useEffect(() => {
      const fetchRanking = async () => {
        const q = query(collection(db, 'ranking'), orderBy('time'));
        const snapshot = await getDocs(q);
        const fetchedRanking = snapshot.docs.map(doc => doc.data());
        setRanking(fetchedRanking);
      };
    
      fetchRanking();
    }, []);
  
    const handleGameFinish = async () => {
      clearInterval(timerId);
      setGameFinished(true);
  
      try {
        await addDoc(collection(db, 'times'), {
          playerName,
          time: timer,
        });
        console.log("Tiempo guardado con éxito");
      } catch (error) {
        console.error("Error al guardar el tiempo: ", error);
      }
    };
  
    const handleSaveScore = async () => {
      const newEntry = { name: playerName, time: timer };
  
      try {
        await addDoc(collection(db, 'ranking'), newEntry);
        const updatedRanking = [...ranking, newEntry];
        updatedRanking.sort((a, b) => a.time - b.time);
        setRanking(updatedRanking);
        setPlayerName('');
        setIsButtonDisabled(true);
        console.log("Puntuación guardada con éxito");
      } catch (error) {
        console.error("Error al guardar la puntuación: ", error);
      }
    };
  
    return (
      <View style={styles.container}>
        <Text style={styles.timer}>{`Tu tiempo: ${timer} s"`}</Text>
        {gameFinished ? (
          <View style={styles.congratulationsContainer}>
            <Text style={styles.congratulationsText}>¡Enhorabuena! Puedes pasar al siguiente nivel</Text>
            <TouchableOpacity onPress={() => {/* Navegación a Juego1 */}} style={styles.nextLevelButton}>
              <Text style={styles.nextLevelButtonText}>Siguiente Nivel</Text>
            </TouchableOpacity>
            <TextInput
              style={styles.input}
              onChangeText={setPlayerName}
              value={playerName}
              placeholder="Ingresa tu nombre"
            />
           <TouchableOpacity onPress={handleSaveScore} style={styles.saveButton} disabled={isButtonDisabled}>
            <Text style={styles.saveButtonText}>Guardar Tiempo</Text>
          </TouchableOpacity>
          {ranking.map((entry, index) => (
            <Text key={index} style={styles.rankingText}>
              {`${index + 1}. ${entry.name}: ${entry.time} segundos`}
            </Text>
          ))}
        </View>
      ) : (
        <>
            <Text style={[styles.targetWord, isCorrect ? styles.correctWord : styles.incorrectWord]}>
  {targetWord}
</Text>
{wordPairs.map((pair, index) => (
  <Animated.View key={index} style={wordAnimations[index].getLayout()}>
    <TouchableOpacity onPress={() => checkWord(pair.correct)} style={[styles.wordContainer, selectedWord === pair.correct && isCorrect != null ? (isCorrect ? styles.correctBorder : styles.incorrectBorder) : styles.incorrectBorder]}>
      <Text style={styles.word}>{pair.correct}</Text>
    </TouchableOpacity>
  </Animated.View>
))}
          </>
        )}
      </View>
    );
  };
  const styles = StyleSheet.create({
    scrollView: {
      flex: 1,
    },
    container: {
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'flex-start', // Cambiado a 'flex-start' para alinear los elementos al principio
      backgroundColor: '#fff',
      padding: window.width > 768 ? 20 : 10,
    },
    timer: {
      position: 'absolute',
      top: 10,
      right: 10,
      fontSize: 20,
      fontWeight: 'bold',
    },
    input: {
      borderWidth: 1,
      borderColor: 'gray',
      padding: 10,
      margin: 10,
      width: '80%',
    },
    saveButton: {
      backgroundColor: '#A9CBFF',// Fondo azul claro
      padding: 10,
      borderRadius: 5,
      borderColor: 'blue',
      borderWidth: 1,
    },
    saveButtonText: {
      color: 'black',// Palabra negra
      fontSize: 18,
      textAlign: 'center',
    },
 
  wordContainer: {
    // otros estilos...
    padding: window.width > 768 ? 15 : 10, // Ajustar el padding según el ancho de la pantalla
  },
  title: {
    color: 'blue',
    fontSize: 24,
    marginTop:30,
    marginBottom: 20,
  },

  targetWord: {
    marginTop: 30,
    color: 'blue',
    fontSize: 24,
    marginBottom: 20,
  },
  correctWord: {
    color: 'green',
  },
  incorrectWord: {
    color: 'red',
  },
  wordContainer: {
    padding: 10,
    margin: 2,
    borderWidth: 1,
    borderColor: 'red',
    backgroundColor: '#f2f2f2', // Fondo gris claro
  },
  correctBorder: {
    borderColor: 'green', // Borde verde si la palabra es correcta
  },
  incorrectBorder: {
    borderColor: 'red', // Borde rojo si la palabra es incorrecta o no seleccionada
  },
  selectedWord: {
    borderColor: 'green', // Borde verde si la palabra es correcta
  },
  word: {
    color: 'black',
    fontSize: 18,
  },
  congratulationsContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  congratulationsText: {
    fontSize: 24,
    textAlign: 'center',
    marginBottom: 20,
  },
  nextLevelButton: {
    backgroundColor: '#A9CBFF',
    padding: 10,
    borderRadius: 5,
    borderColor: 'blue',
    borderWidth: 1,
  },
  nextLevelButtonText: {
    color: 'black',
    fontSize: 18,
  },
});

export default GameScreen;